@import 'normalize.css/normalize';
@import '~@linkinbio-web-components/additional-link-list/index';
@import '~@linkinbio-web-components/social-link-list/index';
@import '~@linkinbio-web-components/payment-link-list/index';
@import '~@linkinbio-web-components/product-list/index';
@import '~@linkinbio-web-components/card-product/index';
@import '~@linkinbio-web-components/profile/index';
@import '~@linkinbio-web-components/your-link/index';
@import '~@app/components/footer/index';
@import '~@linkinbio-web-components/my-mshops/index';
@import '../NotFound/index';
@import '../../components/appearance/wrapper-payment-link-list/index';

.alias {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);

  .alias {
    padding-bottom: rem(56px);
  }

  .payment-links__container-list,
  .your-link__container,
  .product-list__container-section,
  .additional-links__container,
  .lib-links__container,
  .lib-my-mshops__container,
  .profile__subtitle,
  .product-list__container {
    align-self: center;
    max-width: rem(644px);
    width: 100%;
  }

  .profile__subtitle {
    margin-top: 16px;
  }

  .profile__container {
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (width <= 480px) {
    header + * {
      padding-top: rem($andes-spacing-32);
    }
  }

  @media (width > 640px) {
    header + * {
      padding-top: rem($andes-spacing-40);
    }

    .product-list__container {
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      max-width: rem(580px + $andes-spacing-32 * 2);
      padding: rem($andes-spacing-40 $andes-spacing-32);
    }

    header + .product-list__container {
      margin-top: 2.5rem;
    }

    .lib-my-mshops__container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
