$andes-theme: 'mercadopago';

@import '~@andes/common/index';
@import '~@andes/typography/index';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.not-found-page,
.index {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 56px);
  padding: 0 $andes-spacing-20;
  width: 100vw;
}

.andes-title--not-found,
.andes-text--not-found {
  text-align: center;
}