@import '~@andes/card/index';
@import '~@andes/common/index';
@import '~@andes/money-amount/index';
@import '~@andes/typography/index';
@import '~sass-rem/rem';

.footer__container {
  align-items: center;
  border-top: 1px solid;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  font-family: '"Poppins"', sans-serif;
  gap: rem($andes-spacing-16);
  grid-template-columns: repeat(2, 1fr);
  height: rem($andes-spacing-56);
  justify-content: space-between;
  padding: 0 $andes-spacing-16;
  position: inherit;
  width: 100%;
}

.footer-link {
  align-items: center;
  display: flex;
  font-family: $font-family-primary;
  font-size: $andes-spacing-12;
  font-weight: normal;
  line-height: $andes-spacing-16;

  a {
    font-family: $font-family-primary;
    margin-left: $andes-spacing-4;
    text-decoration: none;
  }

  svg,
  path {
    color: inherit;
    fill: currentcolor;
  }
}
